html,
body {
    margin: 0;
    padding: 0;
}

.aside {
    position: fixed;
    top: 12rem;
    left: -5.7rem;
    transform: rotate(90deg);
    animation: 0.6s ease-in-out 0.2s 1 normal forwards running techlineCopyAnimation;
    line-height: 1;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}