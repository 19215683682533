nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding-top: 1rem;
    .navtags {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .hamburger {
            cursor: pointer;
        }
        .dropdown:hover .dropdown-content {
            display: block;
        }
        .dropdown {
            position: relative;
            display: inline-block;
            .dropdown-content {
                display: none;
                position: absolute;
                background-color: #f1f1f1;
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                a {
                    color: black;
                    padding: 12px 16px;
                    text-decoration: none;
                    display: block;
                }
                a:hover {
                    background-color: #ddd;
                }
            }
            .dropbtn {
                display: flex;
                flex-direction: row;
                cursor: pointer;
                .btn {
                    margin-top: -0.2rem;
                }
            }
            p {
                font-size: 18px;
            }
        }
    }
}